<template>
  <div
      class=" swiper classifiedMainCarousel w-full h-96 md:h-128"
  >
    <div class="swiper-wrapper">
      <div
          v-for="banner in banners"
          :key="banner"
          class="swiper-slide"
      >
        <NuxtImg
            :src="banner"
            alt=""
            class="w-full object-cover bg-bottom h-96 md:h-128"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {Autoplay, EffectFade} from "swiper/modules";

const banners = ref([
  "/images/classified/main_1.jpg",
  "/images/classified/main_2.jpg",
  "/images/classified/main_3.jpg",
]);

const swiper = ref(null);
onMounted(() => {
  swiper.value = new useNuxtApp().$swiper('.classifiedMainCarousel', {
    modules: [Autoplay, EffectFade],
    direction: 'horizontal',
    loop: true,
    autoplay: {
      delay: 5000,
    },
    slidesPerView: 1,
    effect: 'fade',
    allowTouchMove: false,
  });
})
</script>

<style></style>
