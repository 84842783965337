<template>
  <div
      class="flex flex-col justify-center items-center w-full m-0 p-0 top-0 bg-transparent shadow"
  >
    <!-- Top Bar -->
    <div
        class="flex flex-col items-start justify-start w-full h-96 md:h-128 relative"
    >
      <ClassifiedMainCarousel
          class="min-h-full h-96 md:h-128"
      />
      <!-- Transparent Layout Background -->
      <div
          class="flex flex-none bg-a-neutral-dark h-96 md:h-128 min-h-full w-full absolute z-30 bg-opacity-25"
      ></div>
      <!-- Transparent Layout Background Ends -->
      <div
          class="flex flex-col h-96 md:h-128 min-h-full w-full absolute z-40"
      >
        <ClassifiedHomeNavMenuBar
            class="h-16 hidden md:flex"
        />
        <ClassifiedHomeNavMenuBarMobile class="h-16 flex md:hidden"/>
        <!-- Overlay Section -->
        <div
            class="flex flex-col items-center justify-center h-112 text-white bg-transparent"
        >
          <div
              class="flex flex-row flex-wrap items-end justify-center mb-1"
          >
            <NuxtImg
                src="/images/main/logo_inv.png"
                class="w-48 hidden md:inline"
                alt=""
            />
            <p class="text-xl md:text-3xl mx-2 text-center">
              It's Magic! All You Need Is Classified Here
            </p>
          </div>
          <p class="text-base md:text-xl mb-2 mx-2 text-center">
            Awesome Stuff New & Used
          </p>
          <ClassifiedInstantSearch
              class="max-w-sm md:max-w-4xl"
          />
        </div>
        <!-- Overlay Section Ends -->
      </div>
    </div>
    <!-- Top Bar Ends-->
    <!-- Bottom Bar-->
    <ClassifiedNavCategoriesBar/>
    <!-- Bottom Bar Ends-->
    <!-- Main Classified Alert -->
    <BaseAlert
        alignText="center"
        class="z-40"
    />
    <!-- Main Classified Alert Ends -->
  </div>
</template>

<script>
</script>
